/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
 import '@angular/localize/init';

 import 'core-js/es6/weak-set';
 import 'core-js/es6/promise';
 import 'core-js/es6/typed';
 import 'core-js/es7/array';
 import 'core-js/es7/object';
 
 // Reflect requries both ES6 and ES7 polyfills
 import 'core-js/es6/reflect';
 import 'core-js/es7/reflect';
 
 
 
 /** IE10 and IE11 requires the following for NgClass support on SVG elements */
 import 'classlist.js';  // Run `npm install --save classlist.js`.
 
 
 
 /**
  * Required to support Web Animations `@angular/platform-browser/animations`.
  * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
  **/
 import 'web-animations-js';  // Run `npm install --save web-animations-js`.
 
 
 
 /***************************************************************************************************
  * Zone JS is required by Angular itself.
  */
 import 'zone.js/dist/zone';  // Included with Angular CLI.
 
 
 
 /***************************************************************************************************
  * APPLICATION IMPORTS
  */
 
 /**
  * Date, currency, decimal and percent pipes.
  * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
  */
 // import 'intl';  // Run `npm install --save intl`.
 /**
  * Need to import at least one locale-data with intl.
  */
 // import 'intl/locale-data/jsonp/en';
 